.faq-container {
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  padding: 5rem 1rem;
  color: #fff;
  background-color: #121212; 
  border-radius: 2px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
}

.faq-title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--color-red); 
  margin-bottom: 1.5rem;
  text-align: center;
}

.faq-category {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--color-red);
  margin-bottom: 1rem;
  text-transform: uppercase;
  border-bottom: 1px solid var(--color-red);; 
  padding-bottom: 0.5rem;
}

.faq-list {
  list-style: none;
  padding: 0;
  margin: 3rem;
}

.faq-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1rem 0;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  transition: color 0.3s ease;
}

.faq-question:hover {
  color: var(--color-red);
}

.faq-icon {
  font-size: 1.25rem;
  color: var(--color-red);
  transition: transform 0.3s ease;
}

.faq-answer {
  overflow: hidden;
  font-size: 1rem;
  color: #ddd; 
  line-height: 1.5;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  border-left: 3px solid #bc1c35; 
  padding-left: 1rem;
}
