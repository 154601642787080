.app__header {
  background: url("../../assets/bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.app__header-h1 {
  font-family: var(--font-base);
  color: var(--color-red);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 100px;
  font-size: 70px;
}

.app__header-img img {
  width: 80%;
}
