.section__padding {
  padding: 5rem 9rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__bg {
  background: url("./assets/bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;
}

.app__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_img {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 80%;
}

.custom__button {
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  border-radius: 1px;
  border: none;
  outline: none;
  cursor: pointer;
}

.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: "tnum" on, "lnum" on;
  line-height: 29.9px;
  font-size: 23px;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
}

.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-red);
  font-size: 64px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

.spoon__img {
  width: 45px;
}

.team__content {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap; 
  gap: 2rem;
  justify-content: center; 
}

.team__member-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  width: 200px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team__member-row:hover {
  transform: translateY(-4px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.team__member-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--color-red);
}

.team__member-position {
  font-size: 1.2rem;
  color: var(--color-red);
  font-weight: bold;
  text-align: center;
}

.team__member-name {
  font-size: 1rem;
  color: var(--color-white);
  text-align: center;
}

.team__future-plans {
  margin-top: 3rem;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 2rem;
  border-radius: 10px;
  color: var(--color-white);
  font-family: var(--font-alt);
  font-size: 16px;
  line-height: 1.8;
  text-align: justify;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.team__contact {
  color: var(--color-red);
  font-weight: bold;
  text-decoration: none;
}

.team__contact:hover {
  text-decoration: underline;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .team__content {
    flex-direction: row; 
    justify-content: center;
  }
}

@media screen and (min-width: 2000px) {
  .custom__button,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .headtext__cormorant {
    font-size: 150px;
    line-height: 210px;
  }

  .spoon__img {
    width: 80px;
  }

  .faq-section div.faq__item {
    margin: unset; 
  }
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img {
    margin: 5rem 0 0 0;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 15px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
}
