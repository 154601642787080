.app__footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black); 
  padding: 4rem 2rem; 
  font: var(--font-base);
}

.app__footer-links {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; 
}


.app__footer-links_contact,
.app__footer-links_quotes {
  flex-basis: calc(50% - 1rem); 
  text-align: center;
  padding: 1rem;
  margin: 0.5rem;
}


@media screen and (max-width: 768px) {
  .app__footer-links_contact,
  .app__footer-links_quotes {
    flex-basis: 100%; 
  }
}

.app__footer-headtext {
  color: var(--color-white);
  font-size: 24px; 
  margin-bottom: 1rem;
  font-weight: bold;
}

.app__footer p {
  color: var(--color-grey); 
  font-size: 18px;
  line-height: 1.5;
  margin-top: 0.5rem;
}

.app__footer-address,
.app__footer-quote {
  color: var(--color-white);
  font-size: 16px;
  line-height: 1.4;
}


.app__footer a {
  color: var(--color-red);
  transition: color 0.3s ease-in-out;
}

.app__footer a:hover {
  color: var(--color-gold); 
}

.app__footer-copyright {
  color: var(--color-grey);
  font-size: 16px;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
}

.app__footer-developer-link:hover {
  text-decoration: underline;
}
