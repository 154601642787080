/* General Navbar Styles */
.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-black);
  padding: 1rem 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
}

/* Logo */
.app__navbar-logo {
  display: flex;
  align-items: center;
}

.app__navbar-logo img {
  width: 70px;
  transition: transform 0.3s ease;
}

.app__navbar-logo img:hover {
  transform: scale(1.1);
}

/* Desktop Navbar Links */
.app__navbar-links {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  gap: 2rem;
}

.app__navbar-links li {
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-white);
  transition: color 0.3s ease, transform 0.3s ease;
}

.app__navbar-links li:hover {
  color: var(--color-grey);
  transform: translateY(-2px);
}

/* Ordering Links (Desktop) */
.app__navbar-order {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.app__navbar-order a {
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-red);
  border-radius: 5px;
  background-color: transparent;
  color: var(--color-white);
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.app__navbar-order a:hover {
  background-color: var(--color-red);
  border-color: var(--color-red);
  color: #fff;
}

.app__navbar-order img {
  width: 20px;
  margin-right: 8px;
}

/* Mobile Navbar Styles */
.app__navbar-smallscreen {
  display: none;
}

.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app__navbar-smallscreen_links {
  list-style: none;
  text-align: center;
  padding: 0;
  margin: 0;
}

.app__navbar-smallscreen_links li {
  margin: 1rem 0;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-white);
  cursor: pointer;
  transition: color 0.3s ease;
}

.app__navbar-smallscreen_links li:hover {
  color: var(--color-grey);
}

.overlay__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.app__navbar-smallscreen_order {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.app__navbar-smallscreen_order a {
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-white);
  transition: color 0.3s ease;
}

.app__navbar-smallscreen_order a:hover {
  color: var(--color-red);
}

.app__navbar-smallscreen_order img {
  width: 40px;
  margin-right: 8px;
}

/* Responsive Styles */
@media screen and (max-width: 1150px) {
  .app__navbar-links {
    display: none;
  }

  .app__navbar-smallscreen {
    display: flex;
  }
}

@media screen and (max-width: 650px) {
  .app__navbar {
    padding: 0.8rem 1.5rem;
  }

  .app__navbar-logo img {
    width: 60px;
  }

  .app__navbar-order {
    display: none;
  }
}

@media screen and (min-width: 2000px) {
  .app__navbar-logo img {
    width: 100px;
  }
}
